 import jsPDF from "jspdf";
 
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
 import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { ToastrService } from "ngx-toastr";
import { AddClosedMonthEndPoient, baseUrl } from "../../service/global.service";
import { ChangeDetectorRef } from '@angular/core';


import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { HttpHelpService } from "../../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import { formatDate } from '@angular/common';
import { DocumentPdfComponent } from "../document-pdf/document-pdf.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-company-documents",
  templateUrl: "./company-documents.component.html",
  styleUrls: ["./company-documents.component.scss"]
})

export class CompanyDocumentsComponent implements OnInit {
  
  curentLang: string = "";
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));

  term: any;
  p: any;
  companyDocumentsData: any = [];
  reportsIncomeForm: FormGroup;
  closedMonthForm: FormGroup;
  addCompanyByAdmin: FormGroup;
  id: any;
  companyData: any = [];
  merchantInfoCompanyData: any = [];
  vatInfoData: any = [];
  currentPage: number = 1;
  limit: number = 400;
  offset: number = 0;
  total: number = 0;
  rowId: number;
  routeId: any;
  companyUUid: any;
  uuid: any;
  fromDate: string | null = null;
  toDate: string | null = null;
  invoicesDataFilters: any = [];
  DocumentsDataFilters: any = [];
  selectedCompany: any;
  activeInvoiceUuid: string | null = null;
  viewData: any;
  customLimit: number = 400;
  public active1 = 1;
  public active2 = 1;
  public active3 = 1;
  public active4 = 1;
  public active5 = 1;
  years: number[] = [];
  disabled = true;
  activeTab: number = 1;
  selectedReportType: string;
  selectedReport: number = 1;
  columnDefs: any[] = [];
  rowData: any[] = [];
  reportsForm: FormGroup ;
  companyId: any;
  selectedPeriod: number = 1;
  MerchantName:any
  MerchantPhone:any
  employeeData:any=[];
  date: Date | undefined;
  dashboardReportsData:any;
   Permision_id:any
   invoiceInfoData: any = [];
   companyClosedMonthData: any = [];
   allInvoicescCompanyDataClosed: any = [];
   LockedMonthInvoicesData: any = [];
   curentLangDocument: string = ''
   Confirm: any;
   highlightedInvoiceUuid: string;
   selectedInvoiceUuid: string | null = null;
   DocumentNumber: any;
    CompanyVat:any;
   MerchantNameEn:any;
   InvoiceId:any
    summaryReportHtml: string = '';
   incomeReportHtml: string = '';
    incomeReportData :any
    pdfInvoiceId:  any;
    selectedFilterYear:  any;
    selectedFilterMonth:  any;
    idMerchant:  any;
    closeRowId:  any;
  gridOptions = {
    components: {
      currencyCellRenderer: this.currencyCellRenderer
    },
    groupIncludeFooter: true,
    groupIncludeTotalFooter: true
  };
 
  onNavChange1(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 5) {
      changeEvent.preventDefault();
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 5) {
      changeEvent.preventDefault();
    }
  }

  
  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
    this.uuid = this.activatedRoute.snapshot.params['id'];
    this.companyUUid = this.uuid;

    this.reportsForm = new FormGroup({
      companyId: new FormControl(this.id),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });

    this.reportsIncomeForm = new FormGroup({
      companyId: new FormControl(this.id),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });

   

    const currentYear = new Date().getFullYear();
     for (let year = currentYear; year <= currentYear + 10; year++) {
      this.years.push(year);
           }


    this.closedMonthForm = new FormGroup({
      year: new FormControl(this.selectedFilterYear , Validators.required),
      month : new FormControl(this.selectedFilterMonth , Validators.required),
      merchant_id : new FormControl(this.idMerchant),
      company_id: new FormControl(this.id),
      office_id: new FormControl(this.officeIdNumber ),
      created_by: new FormControl(this.userRegistId),
    });

    
  }
  filterYear(event: any) {
     this.selectedFilterYear = event.target.value;
     // ضع هنا منطق فلترة البيانات بناءً على السنة المختارة
  };

  filterCloseMonth(event: any) {
     this.selectedFilterMonth= event.target.value;
    }

   closeMonth() {
        this.spinner.show();
        let model = {
          year: Number(this.closedMonthForm.value.year),
          month: Number(this.closedMonthForm.value.month),
          merchant_id: Number(this.idMerchant),
          company_id: Number(this.id),
          office_id: Number(this.officeIdNumber),
          created_by: Number(this.userRegistId),
      };

        this.service.Post(AddClosedMonthEndPoient.POST, model).subscribe((res) => {
            if (res.status === 201 ) {
                Swal.fire({
                    icon: 'success',
                    title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
                });
                this.modalService.dismissAll();
                this.getAllClosedMonthCompany();
             } else if (res.status === 400 || res.status === 403 || res.status === 500 || res.status === 409) {
                let errorMessage;
                switch (res.status) {
                    case 400:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    case 403:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                Swal.fire({
                    icon: 'error',
                    title: errorMessage
                });
            }
            this.spinner.hide();
        });
    };

    getAllClosedMonthCompany() {

      this.spinner.show();
      this.service.getAllClosedMonthCompany(this.id, this.officeIdNumber).subscribe({
        next: res => {
          this.companyClosedMonthData = res;
           
           this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    }

    getAllInvoiceLockedByCompanyId() {

      this.spinner.show();
      this.service.getAllInvoiceLockedByCompanyId(this.id).subscribe({
        next: res => {
          this.allInvoicescCompanyDataClosed = res;
            
           this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    }
    
    getId(id: number) {
      this.closeRowId = id;
     }
    
    changeLockClosedStatus() {
      this.spinner.show();
      
      const updatedBy = this.userRegistId;
      this.service.changeLockClosedStatus(this.closeRowId, updatedBy).subscribe({
        next: (res) => {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
          });
          this.getAllClosedMonthCompany();
          this.spinner.hide();
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            title: err.error.message
          });
          this.spinner.hide();
        }
      });
    }
    
    deleteClosedMonth() {
      this.service.deleteClosedMonth(this.closeRowId).subscribe({
        next: (res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.getAllClosedMonthCompany();
            this.modalService.dismissAll();
          }
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            title: err.error?.message || 'حدث خطأ أثناء محاولة الحذف'
          });
        }
      });
    }
    
    openVerticallyCentered(dataDeleted:any) {
      this.modalService.open(dataDeleted, { centered: true });
    };

    openVerticallyCenteredRejected(dataReject:any) {
      this.modalService.open(dataReject, { centered: true });
    };

    cancleAert() {
      this.spinner.show();
      
      const updatedBy = this.userRegistId;
      this.service.cancleAert(this.InvoiceId, updatedBy).subscribe({
        next: (res) => {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
          });
          this.getAllInvoiceLockedByCompanyId();
       
          window.location.reload();
           this.spinner.hide();
             this.modalService.dismissAll();
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            title: err.error.message
          });
          this.spinner.hide();
        }
      });
    }

   
    getIdInvoiceId(id: number) {
      this.InvoiceId = id;
     }
  executeFunctions(ProductID: number, uploadData: any) {
    this.viewData = uploadData;
    this.getCompanyId(ProductID);
   
    this.openInvoiceXl(this.viewData);
  };

 // currencyCellRenderer(params: any) {
   // const value = params.value;
    //if (typeof value === 'number' && !isNaN(value)) {
    //  const numberFormat = new Intl.NumberFormat('en-US', {
     //   style: 'decimal',
     //   minimumFractionDigits: 2,
    //    maximumFractionDigits: 2
    //  });
    //  return numberFormat.format(value);
   // } else {
    //  return value;
    //}
  //}

  currencyCellRenderer(params: any) {
    const field = params.colDef.field;
    const value = params.value;
  
    // If the field is 'document_number', return the value as a string without formatting
    if (field === 'document_number') {
      return value ? value.toString() : '';
    }
  
    // Apply number formatting for other numeric fields
    if (!isNaN(value) && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  
    return value; // Default return if not a number
}


  

  getCompanyIdValueByUuid() {
    this.service.getCompanyIdByUuid(this.uuid).subscribe({
      next: res => {
        this.companyId = res.company_id;
  
         this.getCompanyInfoById();
         this.getAllUserAssesToCompany();
        // تحديث قيمة الحقل companyId في النموذج
        this.reportsForm.patchValue({
          companyId: this.companyId
        });
      },
      error: error => {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' : (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
        });
      }
    });
  };

  getCompanyIdByUuid(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service.getCompanyIdByUuid(this.uuid).subscribe({
        next: res => {
          this.id = res.company_id;
          this.getCompanyInfoById();
          this.getCompanyIdValueByUuid()
          resolve();
        },
        error: error => {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' : (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
          });
          reject(error);
        }
      });
    });
  };

  getCompanyInfoById() {
    this.service.getCompanyInfoById(this.id).subscribe({
      next: res => {
        this.companyData = res;
       this.idMerchant = this.companyData?.merchant_id
           
        if (this.companyData && this.companyData.merchant && this.companyData.merchant.users) {
          const user = this.companyData.merchant.users.find(user => user.user_type === 100);
  
          if (user) {
             this.MerchantPhone = this.formatPhoneNumber(user.phone_number);
         
           } else {
           }
        } else {
         }
      },
      error: error => {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' :
                 (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
        });
      }
    });
  };

  getAllUserAssesToCompany() {
    this.spinner.show();
    this.service.getAllUserAssesToCompany(this.officeIdNumber, this.id).subscribe({
      next: res => {
        this.employeeData = res;
        if (this.employeeData && this.employeeData.length > 0) {
          this.Permision_id = this.employeeData[0].permission_id;    
        }
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }
  
  openXl2(content2:any) {
    this.modalService.open(content2, { size: 'md' });
  };
   
  formatPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters except '+'
    const cleaned = phoneNumber.replace(/[^ \d]/g, '');
  
    // Ensure the number ends with '+'
    if (!cleaned.endsWith('+')) {
      return `${cleaned}+`;
    }
  
    return cleaned;
  };

  filterMonth(event: any) {
    const selectedValue = parseInt(event.target.value, 10);
    const currentDate = new Date();
  
    let fromDate: Date;
    let toDate: Date;
  
    switch (selectedValue) {
      case 1: // Current month
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case 2: // Last month
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;
      case 3: // Start of the year to the end of the current month
        fromDate = new Date(currentDate.getFullYear(), 0, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case 4: // Last year
        fromDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        toDate = new Date(currentDate.getFullYear() - 1, 11, 31);
        break;
      case 5: // Last two full months (excluding the current month)
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;
      default:
        return;
    }
  
    this.fromDate = this.formatDate(fromDate);
    this.toDate = this.formatDate(toDate);
    this.selectedPeriod = selectedValue;
  
    this.reportsForm.patchValue({
      startDate: this.fromDate,
      endDate: this.toDate,
      period: selectedValue
    });
}

  
  formatDate(date: Date): string {
    const day = ('0' + date?.getDate()).slice(-2);
    const month = ('0' + (date?.getMonth() + 1)).slice(-2);
    const year = date?.getFullYear();
    return `${day}/${month}/${year}`;
  }

  onFromDateChange(event: Date): void {
    this.fromDate = this.formatDate(event);
  }
  
  onToDateChange(event: Date): void {
    this.toDate = this.formatDate(event);
  }

  getAllCompanyDocumentsFilters(fromDate: string, toDate: string, limit: number, offset: number, searchKeyword: string) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
  
    // إذا كانت الكلمة المبحوثة فارغة، استخدم البيانات المحفوظة محليًا
    // if (!searchKeyword) {
    //   this.companyDocumentsData = this.companyDocumentsData.slice(offset, offset + limit);
    //   return;
    // }
  
    // إذا كان هناك كلمة بحث، استدعاء دالة الفلتر
    // this.spinner.show(); // افتح التحميل حسب الحاجة
  
    this.service.getAllCompanyDocumentsComponent(this.id, limit, offset, fromDate, toDate, searchKeyword).subscribe({
      next: (res: any) => {
        this.companyDocumentsData = res.data;
        this.total = res.total;
        this.spinner.hide();
      },
      error: (err: any) => {
         this.spinner.hide();
      }
    });
  };
  

  getAllCompanyDocumentsComponent(fromDate?: string, toDate?: string, limit?: number, offset?: number , searchKeyword ?: string) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllCompanyDocumentsComponent(this.id, limit, offset, fromDate, toDate , searchKeyword).subscribe({
      next: res => {
        this.companyDocumentsData = res.data;
        this.total = res.total;
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  // getAllCompanyDocumentsToPrint(fromDate?: string, toDate?: string) {
  //   this.spinner.show();
  //   this.service.printAllInvoiceByCompanyId(this.id, fromDate, toDate).subscribe({
  //     next: res => {
  //       this.companyDocumentsData = res;
  //       if(this.user_type === 301){

  //       }
  //       const documentPdfComponent = new DocumentPdfComponent(this.spinner, this.service, this.activatedRoute, this.http);
  //       documentPdfComponent.generateAndDownloadAllInvoicesZip(this.companyDocumentsData);
  //       this.spinner.hide();
  //     },
  //     error: () => {
  //       this.spinner.hide();
  //     }
  //   });
  // }

  getAllCompanyDocumentsToPrint(fromDate?: string, toDate?: string) {
    this.spinner.show();
    this.service.printAllInvoiceByCompanyId(this.id, fromDate, toDate).subscribe({
      next: res => {
        this.companyDocumentsData = res;

       
        
        if (this.user_type === 301) {
          this.companyDocumentsData = this.companyDocumentsData.filter((document: any) => document.invoice_type !== 400);
        }
        
        const documentPdfComponent = new DocumentPdfComponent(this.spinner, this.service, this.activatedRoute, this.http);
        documentPdfComponent.generateAndDownloadAllInvoicesZip(this.companyDocumentsData);
       
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }
  

  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate, this.limit, this.offset , this.term);
  }

  loadData() {
    this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate);
  }

  onLimitChange() {
    this.limit = this.customLimit;
    this.offset = (this.currentPage - 1) * this.limit;
    this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate, this.limit, this.offset);
  }

  getCompanyId(id: number) {
    this.routeId = id;
    localStorage.setItem('routeId', this.routeId);
  }

  getInvoiceTypeDescription(invoiceType: number): string {
    switch (invoiceType) {
      case 305:
        return this.curentLang === 'ar' ? 'فاتورة ضريبية' : (this.curentLang === 'en' ? 'Tax Invoice' : 'חשבונית מס');
      case 320:
        return this.curentLang === 'ar' ? 'فاتورة وسند قبض' : (this.curentLang === 'en' ? 'Invoice And Receipt' : 'חשבונית וקבלה');
      case 332:
        return this.curentLang === 'ar' ? 'مطالبة مالية' : (this.curentLang === 'en' ? 'Financial Request' : 'בקשה פינאלית');
      case 330:
        return this.curentLang === 'ar' ? 'فاتورة عكسية' : (this.curentLang === 'en' ? 'Invoice/Transaction Invoice' : ' חשבונית זיכוי ');
      case 400:
        return this.curentLang === 'ar' ? 'سند قبض' : (this.curentLang === 'en' ? 'Receipt' : 'קבלה');
        case 100:
          return this.curentLang === 'ar' ? 'طلبية  ' : (this.curentLang === 'en' ? 'order' : 'לְהַזמִין');
          case 200:
            return this.curentLang === 'ar' ? '  ارسالية' : (this.curentLang === 'en' ? 'mission' : 'תעודת משלוח');

             case 0:
              return this.curentLang === 'ar' ? '  عرض سعر' : (this.curentLang === 'en' ? ' Offer Price' : ' מחיר הצעה');
            
        
      default:
        return this.curentLang === 'ar' ? 'نوع غير معروف' : (this.curentLang === 'en' ? 'Unknown Type' : 'סוג לא ידוע');
    }
  }

  openXl(content: any) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  confirmAction() {
    this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate, this.limit, this.offset, this.term);
    this.getAllReportsExcel(true);
    this.getSummaryReportHtml(true)

    this.getIncomeReportHtml(true);

    this.getIncomeReport(true);
  }
  

  ngOnInit() {
   // this.getPdfByInvoiceId();
    this.getCompanyIdByUuid()
     this.reportsForm = this.fb.group({
      companyId: [null],
      startDate: [''],
      endDate: [''],
      document_type: [1],
      lang: this.curentLang
    });
  
    this.selectedReportType = '1';
  
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
    this.reportsForm.patchValue({
      startDate: this.formatDate(startDate),
      endDate: this.formatDate(endDate)
    });
  
    this.fromDate = this.formatDate(startDate);
    this.toDate = this.formatDate(endDate);
  
    // Additional Initialization logic...
    this.layoutVersion = localStorage.getItem("layout_version");
    if (this.layoutVersion === null || this.layoutVersion === undefined) {
      localStorage.setItem("layout_version", "light-only");
      this.layoutVersion = "light-only";
    } else {
      if (this.layoutVersion === "dark-only") {
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.add("light-only");
      }
    }
  
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  
    this.getCompanyIdByUuid().then(() => {
      this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate);
      this.getAllReportsExcel(true);
      this.getSummaryReportHtml(true);
      this.getIncomeReportHtml(true);
      this.getIncomeReport(true);
      this.getAllClosedMonthCompany();
      this.getAllInvoiceLockedByCompanyId();
    }).catch(error => {
     });

     
  }

 

  // Function to print all invoices
  async downloadAllInvoicesZip() {
    this.getAllCompanyDocumentsToPrint(this.fromDate, this.toDate);
  }

  async downloadAllDocumentsZipFile() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      color: 'green',
      backdrop: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  
    // عرض التنبيه الأولي
    Toast.fire({
      icon: 'success',
      title: this.curentLang === 'ar' ? 'جاري الآن تجهيز الملف' : (this.curentLang === 'en' ? 'The file is now being prepared' : 'הקובץ נמצא כעת בהכנה')
    });
  
    // الاشتراك في الخدمة لتحميل الفواتير
    this.service.downloadAllInvoicesPdf(this.companyId, this.curentLang, this.fromDate, this.toDate).subscribe({
      next: (res) => {
        // التعامل مع الاستجابة الناجحة وتحميل الملف
        const blob = new Blob([res.body], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Invoices-${this.fromDate}-${this.toDate}.zip`; // تخصيص اسم الملف
        link.click();
  
        // تحرير الذاكرة بعد الاستخدام
        window.URL.revokeObjectURL(url);
      },
      error: (error) => {
      
        if(error.status == 400)
          {
  
        // طباعة محتوى الخطأ للتحقق من مكان الرسالة
         const errorMessage = this.curentLang === 'ar' ? "تم العثور على عدد كبير من الفواتير، يرجى تقليل الفترة الزمنية." : 
        (this.curentLang === 'en' ? "Too many invoices found, please reduce the time period." : "מצטערים, נמצאו יותר מ100 חשבונות. אנא הגבל את תקופת הזמן.");

const errorToast = Swal.mixin({
toast: true,
position: 'top',
showConfirmButton: false,
timer: 4000,
timerProgressBar: true,
color: 'red',
backdrop: true,
didOpen: (toast) => {
toast.addEventListener('mouseenter', Swal.stopTimer);
toast.addEventListener('mouseleave', Swal.resumeTimer);
}
});

errorToast.fire({
icon: 'error',
title: errorMessage || 'An error occurred while preparing the file'
});
        // التحقق من وجود الرسائل في `error.error`
      }   
      }
    });
  }
  
  
  
 

  filter(event: any) {
    const selectedValue = event.target.value;
    this.selectedReportType = selectedValue;  // تخزين نوع التقرير المحدد
     
  };


  
  getAllReportsExcel(loadOnly: boolean = false) {
    const {  startDate, endDate, lang } = this.reportsForm.value;
    this.spinner.show();

    let exportObservable;

    switch (this.selectedReportType) {
      case '1':
        exportObservable = this.service.getAllReportsIncomeExel(this.id, startDate, endDate, lang);
        break;
      case '2':
        exportObservable = this.service.getAllConsoliDatedIncomeExel(this.id, startDate, endDate, lang);
        break;
      case '3':
        exportObservable = this.service.getAllReportsDocumentExel(this.id, startDate, endDate, lang);
        break;
      case '4':
        exportObservable = this.service.getAllReportsItemExel(this.id, startDate, endDate, lang);
        break;
      case '5':
        exportObservable = this.service.getAllReportsConsolidatedIitemeExel(this.id, startDate, endDate, lang);
        break;

        case '6':
        exportObservable = this.service.getAllReceiptsDetailsExel(this.id, startDate, endDate, lang);
        break;

        case '7':
          exportObservable = this.service.getDashboardReportExel(this.id, lang);
          break;
      default:
        this.spinner.hide();
        return;
    }

    exportObservable.subscribe({
      next: (res: Blob) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }) as any[][];
          this.updateTableDataFromExcel(excelData);

          if (!loadOnly) {
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'THI-INVOICES.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        };
        reader.readAsArrayBuffer(res);
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }


  getAllReportsPdf(loadOnly: boolean = false) {
    const { companyId, startDate, endDate, lang } = this.reportsForm.value;
    this.spinner.show();

    let exportObservable;

    switch (this.selectedReportType) {
      case '1':
        exportObservable = this.service.getAllReportsIncomePdf(companyId, startDate, endDate, lang);
        break;
      case '2':
        exportObservable = this.service.getAllConsoliDatedIncomePdf(companyId, startDate, endDate, lang);
        break;
      case '3':
        exportObservable = this.service.getAllReportsDocumentPdf(companyId, startDate, endDate, lang);
        break;
      case '4':
        exportObservable = this.service.getAllReportsItemPdf(companyId, startDate, endDate, lang);
        break;
      case '5':
        exportObservable = this.service.getAllConsolidatedItemPdf(companyId, startDate, endDate, lang);
        break;

        case '6':
        exportObservable = this.service.getAllReceiptsDetailsPdf(companyId, startDate, endDate, lang);
        break;

        case '7':
          exportObservable = this.service.getDashboardReportPdf(companyId, lang);
          break;
      default:
        this.spinner.hide();
        return;
    }

    exportObservable.subscribe({
      next: res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'THI-INVOICES.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }



  exportToExcel(): void {
    this.getAllReportsExcel(false);
  }

  displayExcelData(): void {
    this.getAllReportsExcel(true);
 
  }

  shouldFormatWithComma(field: string, value: any): boolean {
    // إذا كان الحقل رقم المستند، لا تضيف الفاصلة الألفية
    if (field === 'document_number') {
      return false;
    }
  
    // إذا كانت القيمة رقمية وحقول أخرى غير رقم المستند، أضف الفاصلة الألفية
    return this.isNumber(value);
  }
  
  isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  
  updateTableDataFromExcel(data: any[][]) {
    if (data.length > 0) {
      const formattedData = data.map(row => row.map(cell => cell));
      
      // Extract column definitions from the first row of the data
      this.columnDefs = formattedData[0].map(key => ({ field: key }));
  
      // Map the data rows to objects with the column headers as keys
      this.rowData = formattedData.slice(1).map(row => {
        const rowObject: any = {};
        formattedData[0].forEach((key, index) => {
          rowObject[key] = row[index];
        });
        return rowObject;
      });
    }
  }

  updateTableData(data: any[]) {
    if (data.length > 0) {
      this.columnDefs = Object.keys(data[0]).map(key => ({ field: key }));
      this.rowData = data;
    }
  }

  loadReportData() {
    this.getAllReportsExcel(true);
  }

  onFileChange(event: any) {
    this.displayExcelData();  // عرض البيانات في الموقع
  }

  
  generatePDF1(  ) {
    const { companyId, startDate, endDate, lang } = this.reportsForm.value;
    this.spinner.show();
    this.service.getAllConsolidatedItemPdf(this.id  , startDate, endDate, this.curentLang).subscribe({
      next: res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'THI-INVOICES.pdf';    
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };
  

  loadReportDataPdf() {
    this.getAllReportsPdf(true);
  }
  exportToPdf(): void {
    this.getAllReportsPdf(false);
  }


  loadReportDataPdfDashboard() {
    this.getAllReportsPdf(true);
  }
 

 

  getSummaryReportHtml(loadOnly: boolean = false) {
    this.spinner.show();
    this.service.getSummaryReportHtml(this.id, this.curentLang).subscribe({
      next: (res: string) => {
        this.summaryReportHtml = res;
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();
      }
    });
  }

  getSummaryReportpdf(loadOnly: boolean = false) {
    const { companyId, startDate, endDate, lang } = this.reportsForm.value;
    this.spinner.show();

    let exportObservable;

    exportObservable = this.service.getSummaryReportpdf(companyId, lang);
     
 
    exportObservable.subscribe({
      next: res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'THI-INVOICES.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  exportToExcelSummary(): void {
    this.getSummaryReportExel(false);
  }

  getSummaryReportExel(loadOnly: boolean = false) {
    const { companyId, startDate, endDate, lang } = this.reportsForm.value;
    this.spinner.show();

    let exportObservable;

    
    
          exportObservable = this.service.getSummaryReportExel(companyId, lang);
  
    exportObservable.subscribe({
      next: (res: Blob) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }) as any[][];
          this.updateTableDataFromExcel(excelData);

          if (!loadOnly) {
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'THI-INVOICES.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        };
        reader.readAsArrayBuffer(res);
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }
  
  exportToPdfDashboard(): void {
    this.getSummaryReportHtml(false);  
      this.getIncomeReportHtml(false);
      this.getIncomeReport(false);
      
  }
 

  getIncomeReportHtml(loadOnly: boolean = false) {
    this.spinner.show();
    const { startDate, endDate } = this.reportsForm.value;

    this.service.getIncomeReportHtml(this.id, this.curentLang, startDate, endDate).subscribe({
      next: (res: string) => {
        this.incomeReportHtml = res;
        this.spinner.hide();
        this.addSortListeners(); // إضافة مستمعات الفرز بعد تحميل HTML
      },
      error: (err: any) => {
        this.spinner.hide();
      }
    });
  }

  ngAfterViewInit() {
    this.addSortListeners(); // تأكد من إضافة مستمعات الفرز بعد عرض الـ HTML
  }

  addSortListeners() {
    setTimeout(() => { // تأكد من أن الـ HTML قد تم عرضه بالفعل
      const headers = document.querySelectorAll('#incomeTable th');
      headers.forEach((header, index) => {
        header.addEventListener('click', () => this.sortTable(index));
      });
    }, 100);
  }

  sortTable(columnIndex: number) {
    const rows = Array.from(document.querySelectorAll('#incomeTable tbody tr'));

    const sortedRows = rows.sort((a, b) => {
      const cellA = a.children[columnIndex].textContent.trim();
      const cellB = b.children[columnIndex].textContent.trim();

      if (!isNaN(Number(cellA)) && !isNaN(Number(cellB))) {
        return Number(cellA) - Number(cellB);
      } else {
        return cellA.localeCompare(cellB);
      }
    });

    const tbody = document.querySelector('#incomeTable tbody');
    tbody.innerHTML = '';
    sortedRows.forEach(row => tbody.appendChild(row));
  }



  getIncomeReport(loadOnly: boolean = false) {
    this.spinner.show();
    const {  startDate, endDate } = this.reportsForm.value;

    this.service.getIncomeReport(this.id,  startDate, endDate).subscribe({
      next: (res: string) => {
        this.incomeReportData = res;
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();
      }
    });
  }

  
   

  addSortFunctionality() {
    // يمكنك إضافة وظائف إضافية هنا إذا لزم الأمر
  }


  openInvoiceXl(content4: any) {
    this.modalService.open(content4, { size: 'xl' }).result.then(() => {
      // شيء يمكن تنفيذه بعد إغلاق المودال، إذا كان ضروريًا
    }, () => {
      // إذا تم إلغاء المودال
    });
  };
  

  getInvoiceId(id:any) {
    this.InvoiceId = id
     
    this.getPdfByInvoiceId()
    this.selectedInvoiceUuid = id; // تعيين الصف النشط

  };
 

  getPdfByInvoiceId() {
    this.spinner.show();
    this.service.getInvoiceAndRecipeToPdf(this.InvoiceId).subscribe({
      next: res => {
        this.invoiceInfoData = res;   
              
        this.Confirm = this.invoiceInfoData?.confirm;
        this.curentLangDocument = this.invoiceInfoData?.language;
        this.pdfInvoiceId  = this.invoiceInfoData?.invoice_id;        

        this.DocumentNumber = this.invoiceInfoData?.document_number;
        this.CompanyVat = this.invoiceInfoData?.company_vat;
        this.MerchantNameEn = this.invoiceInfoData?.merchant?.merchant_name_en;
        this.spinner.hide();

      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  downloadDocumentPdf() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      color: 'green',
      backdrop: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    Toast.fire({
      icon: 'success',
      title: this.curentLang === 'ar' ? 'جاري الآن تجهيز الملف' : (this.curentLang === 'en' ? 'The file is now being prepared' : 'הקובץ נמצא כעת בהכנה')
    });
    this.service.downloadDocumentPdf(this.pdfInvoiceId, this.curentLangDocument).subscribe({
      next: res => {
        this.spinner.hide();
        
        // إنشاء رابط لتحميل الـ PDF
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Invoice-${this.pdfInvoiceId}.pdf`; // تحديد اسم الملف
        link.click();
  
        // إطلاق الـ URL لتجنب أي تسرب في الذاكرة
        window.URL.revokeObjectURL(url);
      },
      error: error => {
        this.spinner.hide();
        console.error('Error downloading the file', error);
      }
    });
  }

  // updateVersionCopy() {
  //   this.spinner.show();
  //   this.service.updateVersionCopy(this.id).subscribe({
  //     next: res => {
  //       this.invoiceInfoData = res;
  //       this.spinner.hide();
  //     },
  //     error: error => {
  //       this.spinner.hide();
  //     }
  //   });
  // };

  async generatePDF2() {
    if (this.invoiceInfoData?.confirm === 0) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'لا يمكن تصدير الفاتورة لأنها غير مؤكدة' : (this.curentLang === 'en' ? 'The invoice cannot be exported because it is not confirmed' : 'לא ניתן לייצא את החשבונית מכיוון שהיא לא אושרה')
      });
      return;
    }
  
    try {
      this.spinner.show();
      const pages = document.querySelectorAll('.page');
      const pdf = new jspdf.jsPDF('p', 'mm', 'a4');
  
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const xOffset = 10;
      const yOffset = 10;
      const pdfPageHeight = pageHeight - yOffset * 2;
      const totalNumberOfPages = pages.length;

      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const pageElement = pages[pageIndex] as HTMLElement;
        const canvas = await html2canvas(pageElement, {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: -window.scrollY,
          windowWidth: pageElement.scrollWidth,
          windowHeight: pageElement.scrollHeight
        });
  
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const imgHeight = (canvas.height * pageWidth) / canvas.width;
        let remainingHeight = canvas.height;
        let yPosition = 0;
  
        while (remainingHeight > 0) {
          if (yPosition > 0) {
            pdf.addPage();
          }
  
          const sliceHeight = Math.min(remainingHeight, pdfPageHeight * canvas.width / pageWidth);
  
          const canvasSlice = document.createElement('canvas');
          canvasSlice.width = canvas.width;
          canvasSlice.height = sliceHeight;
  
          const ctx = canvasSlice.getContext('2d');
          ctx.drawImage(canvas, 0, yPosition, canvas.width, sliceHeight, 0, 0, canvas.width, sliceHeight);
  
          const imgSlice = canvasSlice.toDataURL('image/jpeg', 1.0);
          pdf.addImage(imgSlice, 'JPEG', xOffset, yOffset, pageWidth - 2 * xOffset, (sliceHeight * pageWidth) / canvas.width);
  
          yPosition += sliceHeight;
          remainingHeight -= sliceHeight;
  
          // Adding page numbers
          pdf.setFontSize(10);
          pdf.text(`Page ${pageIndex + 1} of ${totalNumberOfPages}`, pageWidth - 20, pageHeight - 5); // Add page number and total pages to each page
        }
      }
     if(this.user_type == 300 || this.user_type == 301)
     {
      pdf.save(`thinvoices-${this.DocumentNumber}.pdf`);
      this.spinner.hide();
     }
     else
     {
      const pdfBlob = pdf.output('blob');
      await this.sendPdfToApi(pdfBlob, `thinvoices-${this.DocumentNumber}.pdf`);
      this.spinner.hide();
     }
     

      // this.updateVersionCopy();
      // this.getPdfByInvoiceId();
      this.spinner.hide();
    } catch (error) {
       this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'حدث خطأ أثناء تصدير الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while exporting the invoice' : 'אירעה שגיאה במהלך ייצוא החשבונית')
      });
    }
  };

  async sendPdfToApi(pdfBlob: Blob, fileName: string) {
    const formData: FormData = new FormData();
    formData.append('file', pdfBlob, fileName);
    formData.append('companyName', this.CompanyVat); // الرقم الضريبي للشركه
    formData.append('signerName', this.MerchantNameEn); // اسم التاجر انجلش

    try {
      const response: Blob = await this.http.post(`${baseUrl}sign-pdf/sign`, formData, { responseType: 'blob' }).toPromise();
      this.downloadSignedPdf(response, `${fileName}`);
      // Swal.fire({
      //   icon: 'success',
      //   title: this.curentLang === 'ar' ? 'تم إرسال الفاتورة بنجاح' : (this.curentLang === 'en' ? 'Invoice sent successfully' : 'החשבונית נשלחה בהצלחה')
      // });
    } catch (error) {
      // Swal.fire({
      //   icon: 'error',
      //   title: this.curentLang === 'ar' ? 'حدث خطأ أثناء إرسال الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while sending the invoice' : 'אירעה שגיאה במהלך שליחת החשבונית')
      // });
    }
  }

  downloadSignedPdf(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  async generateOnePDF(invoice: any): Promise<jsPDF> {
    const invoiceDate = new Date(invoice.invoice_date);
    const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-GB'); // This will format the date as dd/mm/yyyy
    // Create an HTML element for the invoice
    const invoiceElement = document.createElement('div');
    invoiceElement.style.width = '800px'; // Adjust width as needed
    invoiceElement.classList.add('page', 'm-auto', 'invoice-content');

    invoiceElement.innerHTML =  invoiceElement.innerHTML = `
    <div class="page m-auto  invoice-content" style="width: 800px;">
      <div class="card-2">
        <div class="row m-2 ">
          <div class="col-lg-12">
            <div class="invoice-inner" id="invoice_wrapper">
              <div class="invoice-top">
                <div class="row">
                  <div class="col-sm-4">
                    ${invoice.logo ? `
                    <div class="logo my-2">
                      <img src="${invoice.logo}" alt="logo" style="width: 100px;height: 100px; border-radius: 5%;">
                    </div>
                    ` : ''}
                  </div>
                  <div class="col-sm-4 py-5">
                    <h2 class="text-center water-mark "
                      style="color:#ccc ; font-size:30px ;  opacity: 0.5; 
      transform: rotate(-15deg);
       position: absolute;
       top:25%; 
        left: 0%; 
      transform-origin: center; 
      z-index: -0"
                    >${this.curentLang === 'en' ? 'Accountant copy' : (this.curentLang === 'ar' ? 'نسخة المحاسب' : 'עותק רואה חשבון')}</h2>
                  </div>
                  <div class="col-sm-4"></div>
                </div>
              </div>
              <div class="invoice-info">
                <div class="row">
                  <div class="col-sm-6 mb-30">
                    <div class="invoice-number ">
                      <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">${this.curentLang === 'en' ? 'For ' : (this.curentLang === 'ar' ? 'لحضرة' : 'עבור כבודו')}</h4>
                      <div class="invo-addr-1">
                        <div style="font-weight: 300;"><strong>${invoice.customer_name}</strong></div>
                        ${invoice.customer_address ? `
                          <div style="font-weight: 300;"><strong>${invoice.customer_address}</strong></div>
                        ` : `
                          <div style="font-weight: 300;">
                            <strong>${this.curentLang === 'en' ? 'Customer Vat Number' : (this.curentLang === 'ar' ? 'مشغل رقم' : 'מספר זיהוי עובד')}</strong>
                          </div>
                          <div style="font-weight: 300;"><strong>${invoice.customer_vat_number}</strong></div>
                        `}
                         <div style="font-weight: 300;"><strong>${invoice.customer_id_number}</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-30">
                    <div class="invoice-number text-end">
                      <div class="invo-addr-1">
                        <div style="font-weight: bold;"><strong>${invoice.company_name}</strong></div>
                        <div style="font-weight: 300;">
                          <strong>${invoice.company_address_str}</strong>
                          <strong>${invoice.company_Building_number}</strong>
                          <strong>${invoice.company_address_city}</strong>
                        </div>
                       
                        <div style="font-weight: 300;"><strong>${invoice.company_vat}</strong></div>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                 <div class="col-sm-4"></div>
               
                  <div class="invoice text-center  col-sm-4">
                    <p class="mb-1">${invoice.invoice_reference_number !== null ? `<span>${invoice.invoice_reference_number}</span>` : ''}</p>
                    <div class="mb-1" style="font-weight: bold;">
                      ${invoice.document_number !== null ? `
                        <strong>${invoice.invoice_type == 305 ? 
                          (this.curentLang === 'en' ? 'Tax Invoice Number' : (this.curentLang === 'ar' ? 'فاتورة ضريبية رقم' : ' מספר חשבונית מס ')) :
                          invoice.invoice_type == 320 ? 
                          (this.curentLang === 'en' ? 'Invoice With Receipt Number' : (this.curentLang === 'ar' ? 'فاتورة مع ايصال رقم' : 'חשבונית עם מספר קבלה')) :
                          invoice.invoice_type == 332 ? 
                          (this.curentLang === 'en' ? ' Financial Request Number' : (this.curentLang === 'ar' ? 'مطالبة مالية رقم' : ' תביעה כספית מס ')) :
                          invoice.invoice_type == 400 ? 
                          (this.curentLang === 'en' ? 'Receipt Number' : (this.curentLang === 'ar' ? ' ايصال رقم' : ' מס קבלה ')) :
                          invoice.invoice_type == 330 ? 
                          (this.curentLang === 'en' ? 'Reverce Invoice Number' : (this.curentLang === 'ar' ? ' فاتورة عكسية رقم ' : ' מס חשבונית עסקה ')) :
                          invoice.invoice_type == 100 ? 
                          (this.curentLang === 'en' ? 'Order Number' : (this.curentLang === 'ar' ? 'طلبية رقم' : 'לְהַזמִין ')) :
                          invoice.invoice_type == 200 ? 
                          (this.curentLang === 'en' ? 'Mission Number' : (this.curentLang === 'ar' ? ' ارسالية رقم' : ' תעודת משלוח קבלה ')) :
                          
                          ''}</strong>` : ''}
                      <strong style="font-weight: 400; color: black;">${invoice.document_number}</strong>

                    </div>
                     ${invoice.confirmation_number != null ? `
                  <div style="font-weight: 300;color: black;">
                    <strong style="font-weight: bold;" class="mx-1">${this.curentLang === 'en' ? 'Confirmation Number' : (this.curentLang === 'ar' ? ' رقم تخصيص' : 'מספר הקצאה')}: </strong> 
                    <strong>${invoice.confirmation_number}</strong>
                  </div>` : ''}
                  </div>

                     <div class="col-sm-4  text-end">
                    <div class="mb-0" style="font-weight: bold;">${this.curentLang === 'en' ? ' Date' : (this.curentLang === 'ar' ? 'تاريخ ' : 'תאריך ')}</div>
                      <div style="font-weight: 400; color: black;">${formattedInvoiceDate}</div>
                  </div>
                </div>
              </div>
              <div class="order-summary">
                <div class="table-responsive">
                  <table class="table invoice-table">
                    <thead class="bg-active" style="font-weight: bold;">
                      <tr>
                        <th class="text-center" style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Item Name' : (this.curentLang === 'ar' ? 'اسم الصنف' : 'שם הפריט')}</th>
                         <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Price' : (this.curentLang === 'ar' ? 'السعر ' : ' מחיר ')}</th>
                        <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Quantity' : (this.curentLang === 'ar' ? 'الكمية' : 'כמות')}</th>
                        <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Discount' : (this.curentLang === 'ar' ? 'الخصم' : 'הנחה')}</th>
                        <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Total' : (this.curentLang === 'ar' ? 'المجموع' : 'סה"כ')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${invoice.soldItems?.map(item => `
                        <tr>
                          <td class="text-center" style="font-weight: 400; color: black;">${item.item_name}</td>
                            <td class="text-center" style="font-weight: 400; color: black;">${parseFloat(item.price_per_unit).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          <td class="text-center" style="font-weight: 400; color: black;">${item.quantity}</td>
                         <td class="text-center" style="font-weight: 400; color: black;">${parseFloat(item.discount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          <td class="text-center" style="font-weight: 400; color: black;">${parseFloat(item.sub_total).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                        </tr>`).join('')}
                    </tbody>
                  </table>
                </div>
                <div class="small-table my-4">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                        <div class="col-md-6 p-2">
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'Amount Before Discount:' : (this.curentLang === 'ar' ? 'المبلغ قبل الخصم' : ' סכום לפני הנחה')}</div>
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'Discount:' : (this.curentLang === 'ar' ? 'الخصم' : 'הנחה')}</div>
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'Payment Amount:' : (this.curentLang === 'ar' ? ' المبلغ المدفوع ' : ' סכום תשלום ')}</div>
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'VAT Amount:' : (this.curentLang === 'ar' ? ' المبلغ الضريبي ' : ' סכום עסקה ')}</div>
                          <div style="width: 300px; font-weight: bold;" class="m-1">${this.curentLang === 'en' ? 'Payment Amount Including VAT:' : (this.curentLang === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : ' סכום תשלום כולל עסקה ')}</div>
                        </div>
                        <div class="col-md-6 text-end px-3 p-2">
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.amount_before_discount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.all_discount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.payment_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.vat_percentage).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                          <div style="font-weight: 400; color: black;" class="my-1">${parseFloat(invoice.payment_amount_including_vat).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                         </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ${invoice.invoice_type == 400 || invoice.invoice_type == 320 ? `
              <div class="order-summary">
                <div class="col-lg-12">
                  <div class="mt-4 d-flex justify-content-between">
                    <span style="font-weight: bold; " class="mx-2">${this.curentLang === 'en' ? 'Payment Info' : (this.curentLang === 'ar' ? 'بيانات الدفع ' : ' נתוני תשלום')}</span>
                  </div>
                  <div class="table-responsive">
                    <table class="table invoice-table">
                      <thead>
                        <tr>
                          <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Payment Method ' : (this.curentLang === 'ar' ? 'طرق الدفع ' : 'אמצעי תשלום')}</th>
                          <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Details' : (this.curentLang === 'ar' ? 'تفاصيل ' : 'פרטים')}</th>
                          <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Date' : (this.curentLang === 'ar' ? ' تاريخ ' : ' תאריך')}</th>
                          <th class="text-center"  style="background-color: #E62E2D; color: #fff; font-weight: bold; padding: 10px">${this.curentLang === 'en' ? 'Total' : (this.curentLang === 'ar' ? 'المجموع ' : 'סכום')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        ${invoice.receiptsDetails.map(item => `
                          <tr>
                            <td class="text-center" style="font-weight: 400; color: black;">
                              ${item.payment_type == 1 ? (this.curentLang === 'en' ? 'Cash' : (this.curentLang === 'ar' ? 'كاش' : 'מְזוּמָנִים')) :
                              item.payment_type == 2 ? (this.curentLang === 'en' ? 'Check' : (this.curentLang === 'ar' ? 'شيك' : 'לִבדוֹק')) :
                              item.payment_type == 3 ? (this.curentLang === 'en' ? 'Bank' : (this.curentLang === 'ar' ? 'بنك' : 'בַּנק')) :
                              item.payment_type == 4 ? (this.curentLang === 'en' ? 'Credit' : (this.curentLang === 'ar' ? 'ائتمان' : 'אַשׁרַאי')) : ''}
                            </td>
                            <td class="text-center description-column" style="word-wrap: break-word; max-width: 200px; white-space:normal ;font-weight: 400; color: black;">${item.details}</td>
                            <td class="text-center" style="font-weight: 400; color: black;">${new Date(item.date).toLocaleDateString()}</td>
                            <td class="text-center" style="font-weight: 400; color: black;">${parseFloat(item.amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                          </tr>`).join('')}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="small-table my-4">
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-between">
                      <div class="col-md-6 mt-5"></div>
                      <div class="col-md-6">
                        <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                          <div class="col-md-6 py-1">
                            <span class="py-1">${this.curentLang === 'en' ? 'Deduction at source' : (this.curentLang === 'ar' ? 'الخصم من المصدر ' : 'ניכוי במקור')}: </span>
                          </div>
                          <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                            <span class="mx-1">${parseFloat(invoice.deduction_from_source).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                          </div>
                          <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                            <span>${this.curentLang === 'en' ? 'Total' : (this.curentLang === 'ar' ? 'المجموع' : 'סה"כ')}: </span>
                          </div>
                          <div class="col-md-6 text-end py-1"  style="font-weight: 400; color: black;">
                            <span class="mx-1" >${parseFloat(invoice.total_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6"></div>
                    </div>
                    ` : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`;

    // Add the element to the document body
    document.body.appendChild(invoiceElement);

    try {
        // Create a new jsPDF instance
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4' // You can change this to 'letter' if needed
        });

        // Capture the invoiceElement as an image using html2canvas
        const canvas = await html2canvas(invoiceElement, {
            scale: 1.5, // Adjust scale as needed
            useCORS: true,
            scrollY: -window.scrollY
        });

        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let remainingHeight = canvas.height;
        let yPosition = 0;

        while (remainingHeight > 0) {
            const pageHeight = pdf.internal.pageSize.getHeight();
            const canvasSlice = document.createElement('canvas');
            canvasSlice.width = canvas.width;
            const sliceHeight = Math.min(remainingHeight, pageHeight * canvas.width / imgWidth);
            canvasSlice.height = sliceHeight;

            const ctx = canvasSlice.getContext('2d');
            ctx.drawImage(canvas, 0, yPosition, canvas.width, sliceHeight, 0, 0, canvas.width, sliceHeight);

            const imgSlice = canvasSlice.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgSlice, 'JPEG', 0, 0, imgWidth, (sliceHeight * imgWidth) / canvas.width);

            yPosition += sliceHeight;
            remainingHeight -= sliceHeight;

            if (remainingHeight > 0) {
                pdf.addPage();
            }
        }
        const logoImg = await this.getLogoImageData();

        // Remove the invoiceElement from the document body
        document.body.removeChild(invoiceElement);


        return pdf;
    } catch (error) {
        this.spinner.hide();
        Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'حدث خطأ أثناء تصدير الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while exporting the invoice' : 'אירעה שגיאה במהלך ייצוא החשבונית')
        });
        return null;
    }
}
  


  async generateAllPrintPDF(invoices: any[]) {
    this.spinner.show();
    const pdfs = [];
    for (const invoice of invoices) {
      const pdf = await this.generateOnePDF(invoice);
      if (pdf) {
        pdfs.push({ pdf, document_number: invoice.document_number , invoice_type: invoice.invoice_type});
      }
    }
    return pdfs;
  }
  
  // Function to fetch company logo image data
  async getLogoImageData(): Promise<any> {
    const img = new Image();
    img.src = '../../../../assets/images/logo/thi.png'; // Adjust path as needed
    img.crossOrigin = 'Anonymous';
    img.style.width = '10px';
    img.style.height = '10px';
    await new Promise<void>(resolve => {
      img.onload = () => resolve();
    });
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  }

  async generateAndDownloadAllInvoicesZip(invoices: any[]) {
       
    if(invoices.length === 0)
      {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          color: 'red',
          backdrop:true,
           
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
         Toast.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? '   عذرا لا توجد فواتير لهذة الفترة    ' : (this.curentLang === 'en' ? '  Sorry, there are no invoices for this period ' : '   מצטערים, אין חשבוניות לתקופה זו     ')
        })

        this.spinner.hide();
        return

        
      }

      

    this.spinner.show();
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      color: 'green',
      backdrop:true,
       
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
     Toast.fire({
      icon: 'success',
      title: this.curentLang === 'ar' ? 'جاري الأن تجهيز الملف' : (this.curentLang === 'en' ? ' The file is now being prepared ' : 'הקובץ נמצא כעת בהכנה ')
    })
    this.spinner.show();
     const zip = new JSZip();
  
    try {
      const pdfs = await this.generateAllPrintPDF(invoices);
  
      pdfs.forEach(({ pdf, document_number , invoice_type }) => {
        const pdfBlob = pdf.output('blob');
        zip.file(`invoice_${document_number}_${invoice_type}.pdf`, pdfBlob);
      });
  
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, `invoices_${invoices[0].company_name}.zip`);
       this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'حدث خطأ أثناء إنشاء ملف ZIP' : (this.curentLang === 'en' ? 'An error occurred while creating the ZIP file' : 'אירעה שגיאה במהלך יצירת קובץ ה-ZIP')
      });
    }
    
   
  }
}









